import translate from '../../i18n/translate'
import '../../App.css';
import styles from '../styles/footer.module.css'
import MessengerCustomerChat from 'react-messenger-customer-chat/lib/MessengerCustomerChat';
import tlogo from '../../assets/img/Coloforllogo.png'
import ps from '../../assets/img/logo-google-playstore.svg'
import as from '../../assets/img/logo-apple-appstore.svg'
import wb from '../../assets/img/laptop-outline.svg'
import IconButton from '../../components/iconbutton';
import last from "../../assets/new_images/last.svg";
import logotyp from "../../assets/new_images/logo_typo.svg"
import ordi from "../../assets/new_images/ordi.svg"
import playstore from "../../assets/new_images/playstore.svg"
import applestore from "../../assets/new_images/applestore.svg"
import facebook from "../../assets/new_images/facebook.svg"
import tiktok from "../../assets/new_images/tiktok.svg"
import x from "../../assets/new_images/x.svg"
import instagram from "../../assets/new_images/instagram.svg"
import { useState } from "react";
import french from "../../assets/new_images/french.svg"
import english from "../../assets/new_images/english.svg"
import arabic from "../../assets/new_images/arabic.svg"
import { LOCALES } from "../../i18n";
import { HashLink as Linka } from 'react-router-hash-link';
import Popup from './Popup';

const Footer = ({ language, languageList, updateHotLanguage }) => {

  const selectedFlag = (() => {
    switch (language) {
      case LOCALES.ENGLISH:
        return english;
      case LOCALES.FRANCAIS:
        return french;
      case LOCALES.ARABE:
        return arabic;
      default:
        return null; // Handle default case if necessary
    }
  })();
  const [showPopup, setShowPopup] = useState(false);

  const handleButtonClick = () => {
    setShowPopup(true);
  };

  const handlePopupClose = () => {
    setShowPopup(false);
  };
  return (
    <div className="fulll">
      <div className={`footer ${styles.footerContainer}`}>

        <div className="fond6"></div>

        <div className={`footer1 ${styles.footerContainer1}`}>

          <div className={styles.footerBlock}>


            <a href="https://www.colorfol.com" target="_blank" rel="noopener noreferrer">
              <span className='ftr-logo'>
                <img className="ftr-logo-img" src={logotyp} alt="floatings-icons" />
              </span>
            </a>

          </div>

          <div className={styles.footerBlock}>
            <div className={`ftr-content ${styles.footerContent}`}>
              <h3>{translate('getstart')}</h3>

              <ul>
                <li><a href="download">{translate('downapp')}</a></li>
                <li><a href="#" onClick={handleButtonClick}>{translate('subpricing')}</a>
                  {showPopup && (
                    <Popup
                      message={translate("cooming")}
                      onClose={handlePopupClose}
                    />
                  )}</li>
                <li><a href="#3">{translate('help')}</a></li>
              </ul>
            </div>
          </div>

          <div className={styles.footerBlock}>
            <div className={`ftr-content ${styles.footerContent}`}>
              <h3>{translate('diss')}</h3>

              <ul>
                <li><a href="https://www.artists.colorfol.com">{translate('colart')}</a></li>
                <li><a href="#" onClick={handleButtonClick}>{translate('fmt_ti')}</a>
                  {showPopup && (
                    <Popup
                      message={translate("cooming")}
                      onClose={handlePopupClose}
                    />
                  )}</li>
                <li><a href="#" onClick={handleButtonClick}>{translate('fmt_fiii')}</a>
                  {showPopup && (
                    <Popup
                      message={translate("cooming")}
                      onClose={handlePopupClose}
                    />
                  )}</li>
                  <li><a href="#" onClick={handleButtonClick}>{translate('fmt_fiii')}</a>
                  {showPopup && (
                    <Popup
                      message={translate("cooming")}
                      onClose={handlePopupClose}
                    />
                  )}</li>
                  
                <li><a href="#"  onClick={handleButtonClick}>{translate('fmt_fii')}</a>
                {showPopup && (
                    <Popup
                      message={translate("cooming")}
                      onClose={handlePopupClose}
                    />
                  )}
                </li>
              </ul>
            </div>
          </div>

          <div className={styles.footerBlock}>
            <div className={`ftr-content ${styles.footerContent}`}>
              <h3>{translate('ourcompany')}</h3>

              <ul>
                <li><a href="aboutUs">{translate('waw')}</a></li>
                <li><a href="mission">{translate('ov')}</a></li>
                <li><a href="#3">{translate('ot')}</a></li>
              </ul>

              <h3>{translate('fu')}</h3>
              <div>
                <h6> CMR</h6>
                <div className="follow">
                  <a href="https://www.instagram.com/colorfolapp" target="_blank">
                    <img src={instagram} className="links1" />
                  </a>
                  <a href="https://x.com/colorfolapp" target="_blank">
                    <img src={x} className="links1" />
                  </a>
                  <a href="https://www.tiktok.com/colorfolapp" target="_blank">
                    <img src={tiktok} className="links2" />
                  </a>
                  <a href="https://web.Facebook.com/colorfolapp" target="_blank">
                    <img src={facebook} className="links4" />
                  </a>
                </div>
              </div>
              <div>
                <h6> CI</h6>
                <div className="follow">
                  <a href="https://www.instagram.com/colorfolappci/" target="_blank">
                    <img src={instagram} className="links1" />
                  </a>
                  <a href="https://x.com/colorfolappci" target="_blank">
                    <img src={x} className="links1" />
                  </a>
                  <a href="https://www.tiktok.com/colorfolappci" target="_blank">
                    <img src={tiktok} className="links2" />
                  </a>
                  <a href="https://web.facebook.com/ColorfolAppCI/" target="_blank">
                    <img src={facebook} className="links4" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.footerBlock}>
            <div className={`ftr-content2 ${styles.footerContent}`}>
              <h3>{translate('ft_smt')}</h3>

              <ul>
                <li><a href="#3"><span className="cont">{translate('smt_fi')}</span></a></li>
                <li><a href="#3">{translate('smt_si')}</a></li>
                <li><a href="#3">{translate('smt_ti')}</a></li>
                <li><a href="#3"><span className="cont">{translate('smt_foi')}</span></a></li>
                <li><a href="#3">{translate('smt_fii')}</a></li>
                <li><a href="#3">{translate('smt_sii')}</a></li>
                <li><a href="#3"><span className="cont">{translate('smt_sei')}</span></a></li>
                <li><a href="#3">{translate('smt_hui')}</a></li>
                <li><a href="#3">{translate('smt_nei')}</a></li>
              </ul>
            </div>
          </div>

          <div className={styles.footerBlock}>
            <div className={`ftr-content3 ${styles.footerContent}`}>
              <img src={ordi} className="links5" />
              <a href="https://apps.apple.com/fr/app/colorfol/id1603123058" target="_blank">
                <img src={applestore} className="links3" />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.dev.mobile.colortech&hl=fr" target="_blank">
                <img src={playstore} className="links" />
              </a>
            </div>
          </div>
        </div>

        <div className="copywright">
          <div className="legals">
            <Linka to="cgu"><span className="terms">{translate('terms')}</span></Linka>
            <Linka to="privacy-policy"><span className="privacy">{translate('privacy')}</span></Linka>
            <div className="languages">
              <div className="langg"><img src={selectedFlag} /></div>
              <select className='lsdro' value={language} onChange={updateHotLanguage} id="languages">
                {
                  languageList.map((item) => (
                    <option key={item.code} value={item.value}>
                      {item.code}
                    </option>
                  ))
                }
              </select>
            </div>
            <span className="cpwright">{translate('copyright')}</span>
          </div>
        </div>

      </div>
    </div>
  )
}

export default Footer