import React from 'react';
import styles from '../layouts/styles/cgu.module.css'

function Mission() {
    return (
        <div className={styles.page_wrapper}>
            <div className={styles.background_container}></div>
            <div className={styles.content_container}>
                <div className={styles.all_page}>
                    <h1> Notre Mission  </h1>
                    <p>COLORFOL se distingue comme la solution complète et exclusive pour répondre aux défis
                        et besoins spécifiques des artistes et des fans engagés sur le marché africain de la
                        musique. Notre histoire débute par la volonté de surmonter les lacunes technologiques et
                        structurelles qui entravent le potentiel des talents locaux et limitent l'accès des fans à une
                        expérience musicale enrichissante.
                        Notre produit phare est une plateforme musicale immersive offrant une expérience fluide et
                        sans interruption, disponible en application mobile et en application web.</p>
                    <ul>
                        <li> Pour les artistes, COLORFOL représente bien plus qu'une simple plateforme de
                            streaming. C'est un véritable écosystème qui permet aux créateurs de monétiser
                            rapidement et équitablement leur art. Ils peuvent non seulement mettre leur musique
                            en ligne, choisir le prix de vente et la date de sortie, savoir qui sont leurs fans grâce à
                            la collecte de datas, mais également vendre des places de concerts, et
                            prochainement, offrir des produits dérivés et du merchandising. COLORFOL
                            s'engage à offrir une visibilité accrue aux talents émergents.
                        </li>
                        <li> Pour les fans, COLORFOL propose une expérience musicale tout-en-un. L'achat par
                            paiement mobile, le streaming sans interruption, la possibilité d'écouter hors ligne et
                            une qualité audio optimale sont quelques-unes des fonctionnalités qui définissent
                            notre offre. En un seul endroit, les fans peuvent acheter des albums, suivre l'actualité
                            de leurs artistes préférés, réserver des places de concert et bientôt, acheter du
                            merchandising.
                        </li>
                    </ul>
                    <p>
                        COLORFOL est bien plus qu'une plateforme de musique. C'est un catalyseur de
                        l'écosystème musical africain. Notre compréhension approfondie du marché local, notre
                        plateforme technique robuste adaptée aux réalités africaines et notre proposition de revenu
                        sharing à 30% créent une synergie qui distingue COLORFOL de toute concurrence. En
                        unifiant le streaming, l'achat direct, et bientôt la vente de produits dérivés, COLORFOL se
                        positionne comme le seul digital store offrant une expérience musicale aussi complète et
                        intégrée sur le marché africain.
                    </p>
                </div>
            </div>
        </div>

    );
}

export default Mission;