import React from 'react';
import styles from '../layouts/styles/cgu.module.css'

function AboutUs() {
    return (
        <div className={styles.page_wrapper}>
            <div className={styles.background_container}></div>
            <div className={styles.content_container}>
                <div className={styles.all_page}>
                    <h1> Qui nous sommes ? </h1>
                    <p>COLORFOL, une plateforme novatrice de vente de musique en ligne, se positionne comme
                        un acteur majeur sur le marché africain de la musique enregistrée. L’équipe dynamique et diversifiée de COLORFOL comprend des professionnels
                        chevronnés de l'industrie musicale et des ingénieurs expérimentés.</p>
                    <p>
                        COLORFOL offre aux artistes la possibilité de monétiser directement leur art auprès de leurs
                        fans à travers la vente de leur musique, de tickets de concert, et prochainement de produits
                        dérivés, intégrant ainsi un écosystème complet et sans intermédiaire, tandis que les fans
                        bénéficient d'une expérience musicale immersive avec des fonctionnalités telles que l'achat
                        par paiement mobile, le streaming sans interruption et des offres exclusives.
                    </p>
                    <p>
                        La stratégie de COLORFOL repose sur des partenariats stratégiques avec des artistes
                        locaux, des opérateurs de téléphonie mobile, et des médias spécialisés, renforçant ainsi sa
                        position concurrentielle. La plateforme adopte un modèle économique diversifié, allant du
                        revenu sharing sur les ventes d'albums à des offres premium et la publicité.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default AboutUs;